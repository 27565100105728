/**
 * @ComponentFor ResetPasswordPageViewModel
 */

import React from 'react';
import { styled } from '@glitz/react';
import { URLX, postJson, currentUrl, pathCombine, EpiProperty, epiPropertyValue, Breakpoint } from '@avensia/scope';
import ResetPasswordPageViewModel from './ResetPasswordPageViewModel.type';
import ResetPasswordResultViewModel from './ResetPasswordResultViewModel.type';
import { Main, Part, Appearance } from 'Shared/PageLayout/index';
import { Checkbox } from 'Shared/Fields/Toggle';
import { connectWithFeedback, ConnectPropType } from 'Shared/Button/Feedback';
import * as style from 'Shared/Style';
import { media } from '@glitz/core';
import { Appearance as ButtonAppearance } from 'Shared/Button';

type StateType = {
  showPassword?: boolean;
  resetPasswordSuccessful?: boolean;
  newPassword?: string;
  changeFailedMessage?: string;
};

type ConnectedProps = ResetPasswordPageViewModel & ConnectPropType;

const MainWrapper = styled(Main, {
  width: '100%',
  margin: {
    top: '19px',
    left: 'auto',
    right: 'auto',
  },
  padding: {
    left: '28px',
    right: '28px',
  },
  ...media(style.mediaMinQueries[Breakpoint.Small], {
    maxWidth: 700,
    margin: {
      top: '80px',
    },
    padding: {
      left: '4vw',
      right: '4vw',
    },
  }),
  ...media(style.mediaMinQueries[Breakpoint.Large], {
    maxWidth: 800,
    padding: {
      left: '8vw',
      right: '8vw',
    },
  }),
});

const HeadingWrapper = styled.div({
  ...media(style.mediaMinQueries[Breakpoint.Small], {
    paddingTop: '70px',
    paddingRight: 0,
    paddingBottom: 0,
    paddingLeft: 0,
  }),
});

const Title = styled.h4({
  marginRight: '46px',
  marginTop: '14px',
  marginBottom: '8px',
});

const TokenError = styled.div({
  color: '#FF0000',
  fontWeight: 'bold',
  display: 'flex',
  justifyContent: 'flex-start',
  marginBottom: 20,
  textAlign: 'center',
});

const ErrorMessage = styled.div({
  color: '#FF0000',
  fontWeight: 'bold',
  display: 'flex',
  justifyContent: 'flex-start',
  marginBottom: 20,
  textAlign: 'center',
});
const ShowPasswordWrapper = styled.span({
  marginLeft: 9,
  fontSize: 16,
  fontWeight: 300,
  color: style.secondaryDarker,
});
const PasswordField = styled.input({
  width: '100%',
  marginBottom: style.large,
  height: 39,
  padding: {
    top: 10,
    bottom: 10,
    left: 12,
  },
  border: {
    xy: {
      style: 'solid',
      color: style.tertiaryLightest,
      width: 1,
    },
  },
});

const FormBottom = styled.div({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
});

const PageDescription = styled.div({
  marginBottom: '8px',
});

const SuccessMessage1 = styled.div({});

const ButtonWrapper = styled.div({
  display: 'flex',
  alignItems: 'center',
  margin: {
    top: '35px',
  },
  justifyContent: 'center',
});

const BkgPattern = styled.div({
  ...media(style.mediaMinQueries[Breakpoint.Small], {
    backgroundImage:
      'url(' +
      require('../Shared/Image/PatternLeft.png') +
      '), url(' +
      require('../Shared/Image/PatternRight.png') +
      ') ',
    backgroundPosition: 'top left, top right',
    backgroundRepeat: 'repeat-y',
    position: 'absolute',
    top: 0,
    bottom: 0,
    width: '100%',
    zIndex: -1,
  }),
});

const MenuBkg = styled.div({
  position: 'fixed',
  ...media(style.mediaMinQueries[Breakpoint.Small], {
    position: 'absolute',
  }),
  background: style.primaryDark,
  zIndex: -1,
  top: 0,
  width: '100%',
  height: '65px',
  ...media(style.mediaMinQueries[Breakpoint.Medium], {
    height: '80px',
  }),
});

export default connectWithFeedback()(
  class ResetPassword extends React.Component<ConnectedProps, StateType> {
    newPasswordElement: any;

    constructor(props: ConnectedProps) {
      super(props);
      this.state = {
        showPassword: false,
        newPassword: '',
        changeFailedMessage: '',
        resetPasswordSuccessful: false,
      };
    }

    onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      const newPassword = this.state.newPassword.trim();
      if (!newPassword) {
        return;
      }

      this.setState({ changeFailedMessage: '' });
      const token = currentUrl().searchParams.get('token');
      const email = currentUrl().searchParams.get('email');
      const url = new URLX(pathCombine(location.pathname, 'UpdatePassword'));
      url.searchParams.set('newPassword', newPassword);
      url.searchParams.set('token', token);
      url.searchParams.set('email', email);

      this.props.feedback.push(
        postJson(url)
          .then((result: ResetPasswordResultViewModel) => {
            if (result.success) {
              this.setState({ resetPasswordSuccessful: true });
              return Promise.resolve();
            } else {
              return Promise.reject(result.message);
            }
          })
          .catch(e => {
            this.setState({ changeFailedMessage: e });
            return Promise.reject(null);
          }),
      );
    };

    onPasswordFieldInput = (e: React.FormEvent<HTMLInputElement>) => {
      this.setState({
        newPassword: (e.target as HTMLInputElement).value,
        changeFailedMessage: '',
      });
    };

    onShowPassword = (e: React.ChangeEvent<HTMLInputElement>) => {
      this.setState({ showPassword: e.currentTarget.checked });
    };

    render() {
      const isValid = window.IS_IN_EDIT_MODE || this.props.isTokenValid;
      const FeedbackButton = this.props.feedback.Button;

      return (
        <>
          <BkgPattern />
          <MenuBkg />
          <MainWrapper appearance={Appearance.Narrow}>
            <HeadingWrapper>
              <EpiProperty for={this.props.content.newPasswordTitle} component={Title} />
            </HeadingWrapper>
            <PageDescription>
              {isValid &&
                !this.state.resetPasswordSuccessful && (
                  <EpiProperty for={this.props.content.mainContent} component="div" />
                )}
            </PageDescription>
            <Part>
              {!isValid && (
                <TokenError>
                  <EpiProperty for={this.props.content.invalidTokenMessage} />
                </TokenError>
              )}
              {isValid &&
                !this.state.resetPasswordSuccessful && (
                  <form onSubmit={this.onSubmit}>
                    <PasswordField
                      required
                      type={this.state.showPassword ? 'text' : 'password'}
                      value={this.state.newPassword}
                      minLength={5}
                      placeholder={epiPropertyValue(this.props.content.newPasswordLabel)}
                      onChange={this.onPasswordFieldInput}
                      css={
                        this.state.changeFailedMessage && {
                          border: {
                            xy: {
                              color: '#FF0000',
                              style: 'solid',
                              outlineWidth: 0,
                            },
                          },
                        }
                      }
                    />
                    {!!this.state.changeFailedMessage && <ErrorMessage>{this.state.changeFailedMessage}</ErrorMessage>}
                    <FormBottom>
                      <Checkbox checked={this.state.showPassword} onChange={this.onShowPassword}>
                        <ShowPasswordWrapper>
                          <EpiProperty for={this.props.content.showPasswordLabel} />
                        </ShowPasswordWrapper>
                      </Checkbox>
                    </FormBottom>
                    <ButtonWrapper>
                      <FeedbackButton type="submit" appearance={ButtonAppearance.Primary}>
                        <EpiProperty for={this.props.content.submitButtonCaption} />
                      </FeedbackButton>
                    </ButtonWrapper>
                  </form>
                )}
              {this.state.resetPasswordSuccessful && (
                <SuccessMessage1>
                  <EpiProperty for={this.props.content.passwordResetConfirmation} />
                </SuccessMessage1>
              )}
            </Part>
          </MainWrapper>
        </>
      );
    }
  },
);
